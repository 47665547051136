import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import BaseLink from '../components/common/customlink';
import HomepageFooter from '../components/common/footer';
import PBase from '../components/common/p';
import Item from '../components/personalItem';
import { AppearText, AppearImage } from '../components/common/appear';
import SeoImage from '../assets/images/seo/about.jpg';

const Page = styled.div`
  min-height: 100vh;
  background: #FFF;
  padding-top: 241px;
  max-width: 1241px;
  margin: 0 auto;
  padding: 0 135px;
  padding-top: 241px;
  @media (max-width: 1600px) {
    max-width: 1241px;
    padding: 0 104px;
    padding-top: 241px;
  }
  @media (max-width: 1200px) {
    padding: 0 57px;
    padding-top: 241px;
    min-height: auto;
  }

  @media (max-width: 600px) {
      padding: 0 15px;
      padding-top: 201px;
  }

  width: 100%;
  max-width: 1620px;
  
`;

const Bar = styled.div`
  border-bottom: 1px solid #0064BA;
  width: 100%;
  min-height: 1px;
  margin: 40px 0;
  @media (max-width: 600px) {
      display: none;
  }
`;

const H1 = styled.h1`
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 92px;
    line-height: 80px;
    color: #0064BA;
    /* or 87% */
    @media (max-width: 1200px) {
        font-style: normal;
        font-weight: bold;
        font-size: 92px;
        line-height: 80px;
    }

    @media (max-width: 600px) {
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 54px;
    }
`;

const H3 = styled.h3`
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 53px;

    margin-bottom: 42px;
    color: #0064BA;
    color: ${props => props.light && '#00A8E3'};

    @media (max-width: 1200px) {
        font-size: 32px;
        line-height: 38px;
    }
`;

const P = styled(PBase)`
    max-width: 698px;
    margin-top: 50px;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 100px;
    @media (max-width: 600px) {
        padding-top: 40px;
    }
`;

const Col = styled.div`
    width: 50%;
    padding-right: ${props => props.left && '20px'};
    padding-left: ${props => props.right && '20px'};
    @media (max-width: 600px) {
        width: 100%;
        padding: 0;
        margin-top: ${props => props.second && '50px'};
    }
`;

const Link = styled.a`
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    /* identical to box height */

    text-decoration-line: underline;
    color: #0E0E0E;
    &:hover {
        cursor: pointer;
        color: #0064BA;
        a {
            color: #0064BA;
        }
    }
    @media (max-width: 1200px) {
        font-size: 20px;
        line-height: 24px;
    }
`;
const FooterWrapperContainer = styled.div`
    width: 100%;
    margin-top: 100px;
    padding-top: 57px;
    padding-bottom: 78px;
    @media (max-width: 600px) {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 60px;
    }
`;
const FooterWrapper = styled.div`
    margin: 0 auto;
    max-width: 1200px;
`;

const PersonalPage = ({ data, pageContext: { langKey = 'en'} }) => (
    <>
        <Helmet>
            <title>{data.markdownRemark.frontmatter.title} - Blue Ocean Insurance Broker</title>
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, user-scalable=no"
            />
            <meta
                name="description"
                content={data.markdownRemark.frontmatter.subtitle}
            />
            <meta
                property="og:type"
                content="website"
            />
            <meta
                property="og:url"
                content="https://boinsurancebroker.com/en/personal"
            />

            <meta property="og:title" content={data.markdownRemark.frontmatter.title + ' - Blue Ocean Insurance Broker'} />
            <meta property="og:description" content={data.markdownRemark.frontmatter.subtitle} />
            <meta property="og:image" content={SeoImage} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://boinsurancebroker.com/en/personal" />
            <meta property="twitter:title" content={data.markdownRemark.frontmatter.title + ' - Blue Ocean Insurance Broker'} />
            <meta property="twitter:description" content={data.markdownRemark.frontmatter.subtitle} />
            <meta property="twitter:image" content={SeoImage} />
        </Helmet>
        <Page>
            <H1><AppearText>{data.markdownRemark.frontmatter.title_kh}</AppearText></H1>
            <P><AppearText>{data.markdownRemark.frontmatter.subtitle_kh}</AppearText></P>
            <Row>
                {
                    data.allMarkdownRemark.edges.map((element, index) => {
                        if ( index % 2 !== 0 ) {
                            return <>
                                <Item
                                    title={element.node.frontmatter.title_kh}
                                    image={element.node.frontmatter.hero_image && element.node.frontmatter.hero_image.publicURL}
                                    url={element.node.frontmatter.slug}
                                    icon={element.node.frontmatter.icon && element.node.frontmatter.icon.publicURL}
                                    lang={langKey}
                                />
                                <Bar data-scroll className="animation-on-viewport-image" />
                            </>
                        } else {
                            return <Item
                                title={element.node.frontmatter.title_kh}
                                image={element.node.frontmatter.hero_image && element.node.frontmatter.hero_image.publicURL}
                                url={element.node.frontmatter.slug}
                                lang={langKey}
                                icon={element.node.frontmatter.icon && element.node.frontmatter.icon.publicURL}
                            />
                        }
                    })
                }
            </Row>
            <Row>
                <Col left>
                    <H3>Looking for Commercial<br />solutions?</H3>
                    <BaseLink to={`/${langKey}/commercial`}>
                        <Link >
                            Go to section
                        </Link>
                    </BaseLink>
                </Col>
                <Col right second>
                    <H3 light>Looking for custom<br />solutions?</H3>
                    <BaseLink to={`/${langKey}/contact`}>
                        <Link>
                            Get in touch.
                        </Link>
                    </BaseLink>
                </Col>
            </Row>
        </Page>
        <FooterWrapperContainer>
            <FooterWrapper>
                <HomepageFooter variant={2} />
            </FooterWrapper>
        </FooterWrapperContainer>
    </>
);
export default function myPersonalPage(props) {
    return (
        <StaticQuery
            query={graphql`
                query {
                    markdownRemark(frontmatter: {templateKey: {eq: "personal-page"}}) {
                        frontmatter {
                            title
                            subtitle
                            title_kh
                            subtitle_kh
                        }
                    }
                    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "products-items"}, category: {eq: "personal"}}}) {
                        edges {
                            node {
                                frontmatter {
                                    title
                                    title_kh
                                    slug
                                    date
                                    hero_image {
                                        publicURL
                                    }
                                    icon {
                                        publicURL
                                    }
                                    points {
                                        title
                                        paragraph
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={data => <PersonalPage data={data} {...props} />}
        />    
    )
}